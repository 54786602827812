import React, { useState, useEffect } from 'react'
import Mobilemenu from './Mobilemenu'
import { Link } from 'react-router-dom'
import Axios from '../utilities/Axios'
import Coursecard from '../components/Coursecard'
import axios from 'axios'
import logo from '../images/Spinner.gif'

export default function Course() {
  const [latlng, setLatlng] = useState({
    lat: 0,
    long: 0,
  })
  const [data, setData] = React.useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    checkRegion()
    // getMyLocation()
  }, [])
  const checkRegion = async () => {
    console.log('=============== Enter checkRegion =====================')
    try {
      const uid = localStorage.getItem('uid')
      const res = await Axios.get(`user_details/get_user_region/${uid}`)
      console.log(res, 'checkRegion')
      if (!res?.data?.data[0]?.region) {
        //  localStorage.setItem('isUpdated', false)
        console.log(res, 'checkRegion1')

      } else {
        console.log(res, 'checkRegion2')

        localStorage.setItem('region', res?.data?.data[0]?.region)
        console.log(res?.data?.data[0]?.region, 'checkRegion3')

        // localStorage.setItem('isUpdated', true)
      }

      let reg;
      if (!!localStorage.getItem('uid')) {
        reg = window?.localStorage?.getItem('region')
        console.log('========================region============', reg);
        localStorage.setItem('cname', reg)
      } else {
        localStorage.setItem('ccode', "")
        localStorage.setItem("cname", "");
      }
      const user_id = localStorage.getItem('uid') ? localStorage.getItem('uid') : 0
      let dataToSend = {
        lat: "",
        long: "",
        user_id: user_id,
        country_code: "",
        country_name: reg,
      }
      console.log('====================================', dataToSend);
      await Axios.post('courses/get_all_courses_for_user', dataToSend)
        .then((res) => {
          let d = res.data.data[0]
          setData(d)
          setLoading(false)
          // localStorage.setItem("lat", 17.4486);
          // localStorage.setItem("lng", 78.3908);
        })
        .catch((err) => {
          console.log(err)
        })


    } catch (err) {
      console.log(err, 'err')
    }
  }
  // const getMyLocation = () => {
  //   console.log('=============== Enter getMyLocation =====================')
  //   setLoading(true)
  //   axios
  //     .get('https://geolocation-db.com/json/')
  //     .then((res) => {
  //       let r = res?.data
  //       localStorage.setItem('ccode', r.country_code)
  //       localStorage.setItem('cname', r.country_name)
  //       console.log(res, "getMyLocation");

  //       callApi(r.latitude, r.longitude, r.country_code, r.country_name)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }
  // useEffect(() => {
  //   callApi();
  // }, []);
  //   const getMyLocation = () => {
  //     const location = window.navigator && window.navigator.geolocation;
  // // alert(JSON.stringify(location));
  //     if (location) {
  //       location.getCurrentPosition(
  //         (position) => {
  //           console.log("position.coords.latitude", position.coords.latitude);
  //           console.log(
  //             "position.coords.latitude",
  //             position.coords.longitude
  //           );
  //           let latlngs = {
  //             lat: position.coords.latitude,
  //             long: position.coords.longitude,
  //           };
  //           //setLatlng(latlngs);
  //           callApi(latlngs);
  //         }

  //         // (error) => {
  //         //   this.setState({
  //         //     latitude: "err-latitude",
  //         //     longitude: "err-longitude",
  //         //   });
  //         // }
  //       );
  //     } else {
  //       //alert('');
  //     }
  //   };
  // const user_id = localStorage.getItem('uid') ? localStorage.getItem('uid') : 0
  // let ccode = localStorage.getItem('ccode')
  // let cname = localStorage.getItem('cname')
  // const callApi = async (lat, long, code, cname) => {
  //   localStorage.setItem('lat', lat)
  //   localStorage.setItem('lng', long);

  //   console.log(window?.localStorage?.getItem('region'), "<- reg uid->", localStorage.getItem('uid'), " --->", !!localStorage.getItem('uid'));
  //   let reg;
  //   if (!!localStorage.getItem('uid')) {
  //     reg = window?.localStorage?.getItem('region')
  //     console.log('========================region============', reg);
  //     localStorage.setItem('cname', reg)
  //   } else {
  //     localStorage.setItem('ccode', code)
  //     localStorage.setItem("cname", cname);
  //   }


  //   let dataToSend = {
  //     lat: lat,
  //     long: long,
  //     user_id: user_id,
  //     country_code: ccode,
  //     country_name: reg,
  //   }
  //   console.log('====================================', dataToSend);
  //   await Axios.post('courses/get_all_courses_for_user', dataToSend)
  //     .then((res) => {
  //       let d = res.data.data[0]
  //       setData(d)
  //       setLoading(false)
  //       // localStorage.setItem("lat", 17.4486);
  //       // localStorage.setItem("lng", 78.3908);
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  if (loading) {
    return (
      <div
        className="loader"
        style={{
          minHeight: '90vh',
          minWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={logo}
          alt="loader"
          style={{
            minHeight: '10vh',
          }}
        />
      </div>
    )
  }
  return (
    <div>
      <div id="page">
        {/* Page Title Section Start */}
        <div className="page-title-section section">
          <div className="page-title">
            <div className="container">
              <h1 className="title">All Courses</h1>
            </div>
          </div>
          <div className="page-breadcrumb">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">All Courses</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section section-padding-bottom">
          <div className="container">
            {/* Course Top Bar Start */}
            <div className="row justify-content-between align-items-center max-mb-20">
              <div className="col-sm-auto col-12 max-mb-10">
                {data?.length > 0 ? (
                  <p className="result-count">
                    We found <span>{data.length}</span> courses available for
                    you
                  </p>
                ) : (
                  <p className="result-count">No Data found</p>
                )}
              </div>
              {/* <div className="col-sm-auto col-12 max-mb-10">
                <select className="sort-by">
                  <option selected="selected">Default</option>
                  <option value="popularity">Popularity</option>
                  <option value="date">Latest</option>
                  <option value="price">Price: low to high</option>
                  <option value="price-desc">Price: high to low</option>
                </select>
              </div> */}
            </div>
            {/* Course Top Bar End */}
            {/* Courses Wrapper Start */}
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              {data?.map((list) => (
                <Coursecard
                  name={list.course_name}
                  price={list.price}
                  students={list.students}
                  lessons={list.lessons}
                  image={list.image}
                  id={list.id}
                  duration={list.course_duration}
                  regstatus={list.register_status}
                  status={list.status}
                  type={list.type}
                  tid={list?.teacher_id}
                />
                // console.log("item",list);
              ))}
            </div>
            {/* Courses Wrapper End */}
            <div className="row max-mt-50">
              {/* <div className="col text-center">
                <a
                  href="JavaScript:Void(0);"
                  className="btn btn-outline-primary load-more-btn"
                >
                  Load Mores <i className="fal fa-redo ms-3" />
                </a> 
              </div> */}
            </div>
          </div>
        </div>
        {/* Course Section End */}
        {/* </div> */}
      </div>
    </div>
  )
}
