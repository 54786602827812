import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Mobilemenu from "./Mobilemenu";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import Axios from "../utilities/Axios";
import swal from "sweetalert";
import Img from "./Newfolder/27152922.jpg";
import Img2 from "../images/event-details.jpg";
import Relatedcourses from "../components/Relatedcourses";
// import instructor from ".././images/profile/t1.jpg";
import Glimpsecard from "../components/Glimpsecard";
import moment, { duration } from "moment-timezone";
import parse from "html-react-parser";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";

import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, theme, createStyles } from "@material-ui/core/styles";
import PaymentSuccess from "../pages/PaymentSucess";
import logo from "../images/Spinner.gif";

export default function CourseDetails() {
  //stpper
  const [currstr, setCurrstr] = React.useState("");
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
    })
  );

  const handleNext = () => {
    if (activeStep === 2) {
      setPopuptext1("fadeOutUp");
      setpopupclose1("close");
      setPaymentStatus(false);

      loaddata();
    }
    //for other country
    // else if (activeStep === 1) {
    // //alert(localStorage.getItem("ccode"));
    //   if(localStorage.getItem("ccode")!='IN'){
    //   setPopuptext1('fadeOutUp')
    //   setpopupclose1('close')
    //   setPaymentStatus(false)
    //   loaddata()
    //   }else{
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1)
    //   }
    // }
    // //for other country //
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const ref = useRef();
  const instroctorRef = useRef();
  const { id } = useParams();
  localStorage.setItem("cid", id);
  const Id = localStorage.getItem("cid");
  const im1 = Img;
  const im2 = Img2;
  console.log(id + "iddddddddddddd");
  const localImg = [
    { id: 27152922, img: im1 },
    { id: 28625219, img: im2 },
  ];
  const localReviewIds = [{ id: 75278646 }, { id: 94882839 }, { id: 54552796 }];
  let demo = "";
  console.table(localImg.map((val) => val.img) + "!!!!!IIIIII");

  const [overviewdata, setoverviewdata] = React.useState([]);
  const [relatedCourse, setrelatedCourse] = React.useState([]);
  const [data, setdata] = React.useState([]);
  // const [checked, setChecked] = React.useState(false);
  const [dummy, setDummy] = React.useState(false);
  const [checkvalue, setcheckvalue] = React.useState([]);
  const [teachers, setteacher] = React.useState([]);
  const [glimpses, setglimpse] = React.useState([]);
  const [paystatus, setPaystatus] = React.useState("failed");
  const [duedate, setDuedate] = React.useState("");
  const [dueamount, setDueamout] = React.useState("");
  const [bname, setBname] = React.useState("Register");
  const [bstatus, setBstatus] = React.useState(false);
  const [popuptext, setPopuptext] = React.useState("fadeOutUp");
  const [popuptext1, setPopuptext1] = React.useState("fadeOutUp");
  const [popupclose, setpopupclose] = React.useState("close");
  const [popupclose1, setpopupclose1] = React.useState("close");
  const [teacherid, setTeacherid] = React.useState();
  const [classid, setClassid] = React.useState();
  const [teacherprocess, setTeacherprocess] = React.useState(false);
  const [memoriser, setMemoriser] = React.useState(false);
  const [memoriser1, setMemoriser1] = React.useState("fullPayment");
  const [loading, setLoading] = React.useState(false);
  const [memoriserup, setMemoriserup] = React.useState("");
  const [other, setOther] = React.useState(false);
  const [assignteacherid, setassignteacherid] = React.useState();
  const [assignclassid, setAssignclassid] = React.useState();
  const [curriculamdata, setCurriculamdata] = React.useState([]);
  const [paymentStatus, setPaymentStatus] = React.useState(false);
  const [glimpsestatus, setGlimpsestatus] = React.useState(true);
  const [price, setPrice] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [checklockstatus, setChecklockstatus] = React.useState(true);

  const [editingEnabled, setEditingEnabled] = useState(true);
  const [continueEditingEnabled, setContinueEditingEnabled] = useState(false);

  const sid = localStorage.getItem("uid") ? localStorage.getItem("uid") : 0;
  var lat = localStorage.getItem("lat");
  var long = localStorage.getItem("lng");
  let finaltotalrecords = 0;
  let totalrecords = 0;
  // React.useEffect(() => {

  // }, []);
  const paymentalert = () => {
    swal("", "Account suspended.Please pay the due amount!", "warning");
  };
  React.useEffect(() => {
    setLoading(true);
    let b = localStorage.getItem("price");
    setPrice(Number(b));
    loaddata();
    window.scrollTo(0, 0);

    if (
      !(id === localStorage.getItem("paycid")) &&
      localStorage.getItem("uid") != ""
    ) {
      ref.current.click();
    }
    if (localStorage.getItem("uid")) {
      if (id === localStorage.getItem("paycid")) {
        // alert();
        instroctorRef.current.click();
        // let element = document.getElementById("myDIV");
        // element.classList.toggle("active");
        // toast(
        //   "Please go to Instructors tab, to pick your teacher, to complete the registration.",
        //   {
        //     position: "bottom-center",
        //     autoClose: false,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "dark",
        //     // type:"info"
        //   }
        //);
        swal(
          "",
          "Please go to Instructors tab, to pick your teacher, to complete the registration.Please pay the due amount!",
          "info"
        );
      }
    }
  }, [id]);

  const languageOptions = ['Telugu', 'English', 'Sanskrit'];

  const loaddata = async () => {
    // if()
    let ccode = localStorage.getItem("ccode");
    let cname = localStorage.getItem("cname");
    await Axios.post("courses/get_single_course_for_user", {
      course_id: id,
      student_id: sid,
      lat: parseFloat(lat),
      long: parseFloat(long),
      country_code: ccode,
      country_name: cname,
    })
      .then((res) => {
        console.log("result::::::::::::", res);
        let d = res?.data?.data[0][0];
        let glimpse = res?.data?.data[1];
        let teacherdata = res?.data?.data[2];
        let curriculamdata = res?.data?.data[3];
        {
          /* sorting array by date*/
        }
        curriculamdata.sort(function (a, b) {
          return (
            parseInt(
              a.week.slice(6, 10) + a.week.slice(3, 5) + a.week.slice(0, 2)
            ) -
            parseInt(
              b.week.slice(6, 10) + b.week.slice(3, 5) + b.week.slice(0, 2)
            )
          );
        });
        setoverviewdata(d);
        setteacher(teacherdata);
        setdata(res.data.data);
        setglimpse(glimpse);
        setglimpse(glimpse);
        let payment = res?.data?.payment_status;

        if (payment == "half") {
          setMemoriser1("halfPayment");
          localStorage.setItem("payment_type", "full");
        } else if (
          payment == "pending" ||
          payment == "half" ||
          (res?.data?.teacher > 0 && payment == "failed" && activeStep != 1)
        ) {
          setActiveStep(2);
          // alert(teacherprocess)
        }
        setPaystatus(payment);
        // //for other country
        // if(res?.data?.teacher>0 && localStorage.getItem("ccode")!='IN'){
        //   setPaymentstatus('success')
        // }else{
        //   setPaymentstatus('')
        // }
        // //for other country//
        setDuedate(res?.data?.due_date);
        //code for lock curriculum for fee dues
        if (payment == "pending" || payment == "half") {
          let newdue_date;

          if (res?.data?.due_date != "") {
            newdue_date = res?.data?.due_date;
          }

          if (payment == "pending") {
            newdue_date = "2022-03-10";
          }
          // newdue_date = "18-Jan-2022";

          let cdate = moment(new Date()).format("YYYY-MM-DD");
          newdue_date = moment(new Date(newdue_date)).format("YYYY-MM-DD");
          // add 7 days

          const date = new Date(newdue_date);
          date.setDate(date.getDate() + 10);

          let formatteddue_date = moment(new Date(date)).format("YYYY-MM-DD");
          if (
            new Date(cdate).getTime() < new Date(formatteddue_date).getTime()
          ) {
            setChecklockstatus(true);
          } else {
            setChecklockstatus(false);
          }
        } else {
          setChecklockstatus(true);
        }
        console.log(checklockstatus);
        setDueamout(res?.data?.due_amount);
        setassignteacherid(res?.data?.teacher);
        if (res?.data?.teacher > 0) {
          localStorage.removeItem("paycid");
        }
        setAssignclassid(res?.data?.assignClass_id);
        setCurriculamdata(curriculamdata);
        let myMemo = data?.data?.[0]?.[0]?.memar
        console.log('=================data mymemo===================')
        console.log("data mymemod", d)
        console.log('====================================')
        console.log('=============myMemo=======================')
        console.log("myMemo", myMemo)
        console.log('====================================')
        setMemoriserup(d?.memar);
        setMemoriser(d?.memar);
        showglimpse();
        //let array = ["Jagadguru", "Geetacharya", "Akshra Brahma", "Yogeshwara"];
        // console.log("coursename", overviewdata?.course_name);
        // if (overviewdata?.course_name) {
        //   for (let i = 0; i < array.length; i++) {
        //     if (overviewdata?.course_name == array[i]) {
        //       setOther(true);
        //       console.log("fortestst", other);
        //     }
        //   }
        // }
        if (localStorage.getItem("uid")) {
          setBname("Register");
        }
        if (paystatus == "success") {
          setBname("Registered");
          setBstatus(true);
        }
        callApi();
      })
      .catch((err) => { });
    setLoading(false);
  };

  var lat = localStorage.getItem("lat");

  // React.useEffect(() => {
  //   var lat = localStorage.getItem("lat");
  //   var long = localStorage.getItem("lng");
  //   console.log(lat, " ", long);
  //   Axios.post("courses/get_all_courses_for_user", {
  //     lat,
  //     long,
  //   })
  //     .then((res) => {
  //       let d = res?.data?.data[0];
  //       setrelatedCourse(d && d);
  //       console.log(relatedCourse, "dsdsjdsdjfsdjfsdjkfsjdfhsdjf");
  //     })
  //     .catch((err) => {});
  // }, []);
  var lat = localStorage.getItem("lat");
  var long = localStorage.getItem("lng");
  let ccode = localStorage.getItem("ccode");
  let cname = localStorage.getItem("cname");
  const user_id = localStorage.getItem("uid") ? localStorage.getItem("uid") : 0;
  const callApi = async () => {
    await Axios.post("courses/get_all_courses_for_user", {
      lat: parseFloat(lat),
      long: parseFloat(long),
      user_id: user_id,
      country_code: ccode,
      country_name: cname,
    })
      .then((res) => {
        // console.log("callApi:::::::::",id);
        let d = res?.data?.data[0];

        let dd = d.filter((item) => item.id.toString() !== id);
        setrelatedCourse(dd && dd);
      })
      .catch((err) => { });
  };

  React.useEffect(() => {
    let array = ["Bhagavad Gita Course"];

    for (let i = 0; i < array.length; i++) {
      console.log("hhh", overviewdata?.type)
      if (overviewdata?.type == array[i]) {
        console.log("whattt", array[i])
        console.log("coursenameuse", overviewdata?.type);
        //alert(overviewdata?.course_name);
        setOther(true);
      }
    }
  }, [overviewdata?.course_name]);
  const assignteacher = () => {
    const uid = localStorage.getItem("uid");
    Axios.post("student/student_assign_to_teacher", {
      student_id: uid,
      course_id: id,
      teacher_id: teacherid,
      class_id: classid,
    }).then((res) => {
      // console.log("callApi:::::::::", res);
      if (res?.data?.status === 200) {
        localStorage.removeItem("paycid");
        // localStorage.removeItem("price");
        loaddata();
        setPopuptext("fadeOutUp");
        setpopupclose("close");
        toast(res?.data?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    });
  };
  const pickTeacher = (e, tid, cid) => {
    // console.log(e, tid, cid)
    //setChecked(!checked);
    // let checkvalue1 = checkvalue.slice()
    // console.log(checkvalue1.length)
    // if (checkvalue1.length == 0) {
    //   checkvalue1[tid] = tid
    //   setcheckvalue(checkvalue1)
    // } else {
    //   checkvalue1.forEach((item, index) => {
    //     console.log(item, '-----', index)
    //   })
    // }
    // setcheckvalue((prev) => {
    //   if (prev.includes(tid)) {
    //     return prev.filter((x) => x !== tid)
    //   } else {
    //     return [...prev, tid]
    //   }
    // })
    // setcheckvalue(checkvalue1)
    //remove comments
    if (e.target.checked) {
      // setPopuptext('fadeInUp')
      // setpopupclose('')
      setTeacherid(tid);
      setClassid(cid);
    } else {
      setTeacherid(null);
    }
  };
  const showglimpse = () => {
    setGlimpsestatus(true);
  };
  const hideglimpse = () => {
    setGlimpsestatus(false);
  };
  const closepop = () => {
    console.log(assignteacherid, "closepop");
    setPopuptext("fadeOutUp");
    setpopupclose("close");
    setActiveStep(0);
    setTeacherid(null);
    // let checkvalue1 = checkvalue.slice()
    // checkvalue1[teacherid] = checkvalue1[teacherid]
    //console.log(checkvalue1, 'checkvalue1')
    // setcheckvalue(checkvalue1)
  };
  const navigate = useNavigate();
  const Buythiscourse = () => {
    setDummy(true);
    let price = overviewdata?.price;
    let amount =
      paystatus == "half"
        ? parseInt(overviewdata?.price) / 2
        : parseInt(overviewdata?.price);

    localStorage.setItem("payment_type", "full");

    if (paystatus == "half") {
      //setMemoriser1("halfPayment")
      localStorage.setItem("payment_type", "full");
    }
    let uid = localStorage.getItem("uid");
    let currency_str = price?.substr(-3);

    let email_id = localStorage.getItem("email_id");
    const obj = {
      user_id: uid,
      course_id: id,
      email_id: email_id,
      amount: amount,
      payment_type: localStorage.getItem("payment_type"),
      currency: currency_str,
    };
    if (uid != null) {
      // console.log(obj, "obj");

      Axios.post("payments/create_order", obj)
        .then((res) => {
          console.log('====================================')
          console.log("response", res)
          console.log('====================================')
          localStorage.setItem("id", res?.data?.id);
          localStorage.setItem("order", res?.data?.order);
          localStorage.setItem("mail", res?.data?.email_id);
          console.log(res?.data, "data");
          console.log(res?.data?.order, "order");
          if (res?.data?.status === 200) {
            displayRazorpay();
            let price = overviewdata?.price;
            let amount = price?.replace(/[^\d.-]/g, "");
            window.localStorage.setItem("price", overviewdata?.price);
            window.localStorage.setItem("amount", amount);
          }
        })
        .catch((err) => {
          console.log('====================================')
          console.log("error occured")
          console.log('====================================')
          console.log(err);
        });

      const displayRazorpay = async () => {
        console.log("entered displayRazorpay");
        const orderId = window.localStorage.getItem("order");
        let uname = localStorage.getItem("uname");
        let phone_number = localStorage.getItem("phone_number");
        let email_id = localStorage.getItem("email_id");
        let city = localStorage.getItem("city");
        var options = {
          key:
            currency_str == "INR"
              ? "rzp_live_9E17mBGI5uOsWH"
              : "rzp_live_KU0srEcrZcYHsE",
          amount: `${amount * 100}`,
          currency: currency_str /*INR||USD*/,
          name: "Rushividyabyasam",
          description: "Amount Transaction",
          image: "https://cdn.razorpay.com/logos/Ij8akwhTLP5vsh_large.png",
          order_id: orderId,
          prefill: {
            name: uname,
            email: email_id,
            contact: phone_number,
          },
          notes: {
            address: city,
          },
          theme: {
            color: "#ee9200",
          },
          handler: (res) => {
            console.log(res.razorpay_payment_id, "razorpay_payment_id");
            console.log(res.razorpay_order_id, "razorpay_order_id");
            console.log(res.razorpay_signature, "razorpay_signature");

            window.localStorage.setItem(
              "razorpay_payment_id",
              res.razorpay_payment_id
            );
            window.localStorage.setItem(
              "razorpay_order_id",
              res.razorpay_order_id
            );
            window.localStorage.setItem(
              "razorpay_signature",
              res.razorpay_signature
            );
            // navigate('/paymentsuccess')
            setPaymentStatus(true);
          },
        };
        var rzp1 = new window.Razorpay(options);
        console.log(rzp1, "resss");
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          let data = {
            razorpay_order_id: response?.error?.metadata?.order_id,
            razorpay_payment_id: response?.error?.metadata?.payment_id,
          };
          Axios.post("payments/payment_failure", data)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          // console.log("payment failed", response);
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
      };
    } else {
      //alert("Please Login before buy the course");
      navigate("/signup");
    }
  };

  const handleUpdate = () => {
    // assignteacher();
    let uid = localStorage.getItem("uid");
    // let data = {
    //   student_id: uid,
    //   course_id: overviewdata?.course_id,
    //   status: memoriser,
    // };

    let data = {
      student_id: uid,
      // course_id: overviewdata?.course_id,
      status: memoriser,
    };
    console.log(data, "data");
    Axios.post("student/student_memorizer", data)
      .then((res) => {
        // console.log(res);
        if (res?.data?.status === 200) {
          loaddata();
          toast("successful", {
            position: "bottom-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOther(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Internal Server Error", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleContinue = () => {
    // assignteacher();
    console.log("memoriser", memoriser)
    if (!memoriser) {
      alert("Please Select Language")
      // toast('', {
      //   position: 'bottom-right',
      //   type: 'error',
      //   theme: 'colored',
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // })
    } else {
      setEditingEnabled(false);
      setContinueEditingEnabled(true)
      let uid = localStorage.getItem("uid");
      // let data = {
      //   student_id: uid,
      //   course_id: overviewdata?.course_id,
      //   status: memoriser,
      // };

      let data = {
        student_id: uid,
        // course_id: overviewdata?.course_id,
        status: memoriser,
      };
      // console.log(data, "data");
      Axios.post("student/student_memorizer", data)
        .then((res) => {
          // console.log(res);
          if (res?.data?.status === 200) {
            // loaddata();
            // toast("successful", {
            //   position: "bottom-right",
            //   type: "success",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "colored",
            // });
            setTeacherprocess(true);
            //setOther(false)
            //setShow(false)
          }
          setPopuptext("fadeOutUp");
          setpopupclose("close");

          assignteacher();
        })
        .catch((err) => {
          console.log(err);
          toast("Internal Server Error", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };
  const handleChange1 = (e) => {
    if (!continueEditingEnabled) {
      console.log('====================================');
      console.log("can updated");
      console.log('====================================');
      // console.log(e.target.checked);
      // console.log(memoriser, "selected");
      // setContinueEditingEnabled(true)
      setMemoriser(e.target.value);
      setMemoriserup(e.target.value);
    }

  };
  const handleChange2 = (e) => {
    setMemoriser1(e.target.value);
    // if (e.target.checked) {
    //   setMemoriser1("halfPayment");
    // } else {
    //   setMemoriser1("fullPayment");
    // }
    // console.log(memoriser, "selected");
    // setMemoriser("");
    // setMemoriserup(e.target.value);
    // console.log(memoriserup);
  };

  function getSteps() {
    return [
      <b style={{ color: "purple" }}>'Pick a Teacher'</b>,
      <b style={{ color: "purple" }}>'Disclaimer'</b>,
      <b style={{ color: "purple" }}>'Select Donation Type'</b>,
      // <b style={{ color: 'purple' }}>'Memorizer'</b>,
    ];
  }
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div>
            {popuptext !== "fadeInUp" ? (
              <form class="form-group">
                {teachers.length > 0 ? (
                  teachers.map((teacher, i) => {
                    if (
                      assignteacherid === teacher?.user_id &&
                      assignclassid === teacher.class_name
                    ) {
                      var checked_str = true;
                    } else {
                      var checked_str = false;
                    }

                    if (assignteacherid > 0) {
                      var disabled_str = true;
                    } else {
                      var disabled_str = false;
                    }
                    let paystatus = "success";
                    return (
                      <div className="course-instructor t-sec">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="profile-image t-prof">
                              <img src={teacher?.image} alt="instructor" />
                              <div className="c-content t-a-s">
                                <div>Available slots</div>
                                <div className="c-values a-slots">
                                  {teacher.available_slots}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="profile-info t-p-cnt">
                              <div class="t-slot-sec">
                                <h4 class="mg-b-10">{teacher?.fullname}</h4>
                                {teacher?.available_slots > 0 ? (
                                  <div className="pick-t">
                                    {paystatus == "success" ||
                                      paystatus == "pending" ||
                                      paystatus == "half" ? (
                                      <label for="login-form-remember">
                                        {disabled_str && checked_str
                                          ? "Assigned"
                                          : paystatus
                                            ? "Pick the teacher"
                                            : ""}
                                      </label>
                                    ) : null}
                                    {paystatus == "success" ||
                                      paystatus == "pending" ||
                                      paystatus == "half" ? (
                                      <input
                                        type="radio"
                                        // checked={
                                        //   !disabled_str
                                        //     ? checkvalue.includes(
                                        //         teacher?.user_id,
                                        //       )
                                        //     : checked_str
                                        // }
                                        disabled={disabled_str}
                                        name={"pick_teacher_"}
                                        id={"pick_teacher_" + teacher?.user_id}
                                        onClick={(e) => {
                                          pickTeacher(
                                            e,
                                            teacher?.user_id,
                                            teacher?.class_name
                                          );
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : assignteacherid > 0 &&
                                  assignteacherid == teacher?.user_id ? (
                                  <>
                                    {" "}
                                    <div className="pick-t">
                                      <label for="login-form-remember">
                                        {disabled_str && checked_str
                                          ? "Assigned"
                                          : paystatus
                                            ? "Pick the teacher"
                                            : ""}
                                      </label>
                                      <input
                                        type="checkbox"
                                        checked={
                                          !disabled_str
                                            ? checkvalue.includes(
                                              teacher?.user_id
                                            )
                                            : checked_str
                                        }
                                        disabled={disabled_str}
                                        name={
                                          "pick_teacher_" + teacher?.user_id
                                        }
                                        id={"pick_teacher_" + teacher?.user_id}
                                        onClick={(e) => {
                                          pickTeacher(
                                            e,
                                            teacher?.user_id,
                                            teacher?.class_name
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="pick-t">
                                    <label for="login-form-remember">
                                      <span style={{ color: "red" }}>
                                        Slots not available
                                      </span>
                                    </label>
                                  </div>
                                )}
                              </div>
                              {/* {console.log(teacher.slot_time, 'slot_time')} */}
                              <div className="t-h-t">
                                <p className="c-time">
                                  Class Time:{" "}
                                  <span>
                                    {teacher.slot_time
                                      .split(",")
                                      .map((time, i) => (
                                        <>
                                          {(i === 0 ? "" : ", ") +
                                            moment(time)
                                              .tz(moment.tz.guess())
                                              .format("dddd hh:mm A z")}
                                        </>
                                      ))}
                                  </span>
                                </p>
                              </div>

                              <p>
                                {parse(teacher?.description) ||
                                  "Lorem Ipsum is simply dummy text of the"}
                              </p>
                              <h6>Details</h6>
                              {/* <p>
                                      DOB:{" "}
                                      <span>
                                        {teacher?.dob || "27/FEB/1980"}
                                      </span>
                                    </p> */}
                              {/* <p>
                                      Association Duration:{" "}
                                      <span>
                                        {teacher?.associated_from} years
                                      </span>
                                    </p> */}
                              <p>
                                Courses:
                                <span> {teacher?.courses}</span>
                              </p>
                              {/* <div className="mg-t-15 t-contact">
                                      <p>
                                        <i class="fas fa-mobile-alt"></i>
                                        <span>
                                          {teacher.phone_number ||
                                            "040-2713 2550"}
                                        </span>
                                      </p>
                                      <p>
                                        <i class="far fa-envelope t-mail"></i>
                                        {
                                          <span>
                                            {teacher.email_id ||
                                              "info@rushipeetham.org"}
                                          </span>
                                        }
                                      </p>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 style={{ color: "red" }}>"No Data available"</h1>
                )}
              </form>
            ) : null}
          </div>
        );
      case 2:
        return (
          <div>
            {!paymentStatus ? (
              <form class="form-group">
                <div className="row">
                  <div className="col-md-12 col-12 align-self-center">
                    <div className="freecourse-popup-content">
                      {/* <h6></h6> */}
                      <h5>
                        <span style={{ color: "#fe880e" }}>DONATIONS</span> for
                        Rushipeetham Charitable Trust
                      </h5>
                      <br />
                      <h6>
                        {paystatus == "half"
                          ? ""
                          : "Payment for course registration"}
                      </h6>
                      {/* <p className="title1">
                    You can pay through Paypal, Credit Card, Debit Card, Net
                    Banking, UPI and Many Other payment methods.
                  </p> */}
                      <br />
                      {/* {console.log(memoriser1,"Raviiii")} */}
                      <div className="row max-mb-n20">
                        <div className="col-md-12 max-mb-20 disclaimer">
                          <h6>
                            Total Payment({" "}
                            {paystatus == "half"
                              ? parseInt(overviewdata?.price) / 2
                              : parseInt(overviewdata?.price)}{" "}
                            {window.localStorage.getItem("price").substr(-3)} ){" "}
                          </h6>
                        </div>
                      </div>
                      <br />

                      <div className="freecourse-download-form">
                        <div className="row max-mb-n20">
                          <div className="col-md-12 max-mb-20 disclaimer">
                            <button
                              className="btn btn-primary btn-hover-secondary"
                              onClick={() => Buythiscourse()}
                              type="button"
                            >
                              PROCEED TO PAY
                            </button>
                            {/* <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={() => {
                            alert("Are you Sure to Pay");
                          }}
                        >
                          Full Payment(₹ {parseInt(overviewdata?.price)})
                        </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <PaymentSuccess />
            )}
          </div>
        );
      case 1:
        return (
          <div>
            <div className="row">
            <div className="col-md-1 col-1"></div>
              <div className="col-md-10 col-10 align-self-center">
                <div className="freecourse-popup-content text-start">
                  <h6>Disclaimer</h6>
                  <p className="title1">
                    Due to unforeseen circumstances if a teacher goes on leave
                    of absence, Rushividyabhyasam team reserves the right to
                    assign the impacted students to other classes or assign a
                    new teacher to the class.
                  </p>
                  <p className="title1">
                    <h6>Note:</h6>
                    <b>Fees is non refundable.</b> Fees already paid to course
                    is eligible for return (or adjusted to another course in
                    rushividyabhyasam.org) ONLY if it meet the below criteria:
                    <ul>
                      <li>
                      The course for which the amount was paid, was
                      cancelled by rushividyabhyasam.org.
                      </li>
                      <li>
                      An incorrect amount was charged.
                      </li>
                      <li>
                      <strong style={{ color: "purple" }}>
                      All fees paid towards any of the courses offered by Rushividyabhyasam get transferred to Rushipeetham Chartitable Trust, where the funds are completely utilized to support a wide range of charitable activities, including Go Seva, Encouragement to vedic schools, Medical Camps and Scholarship for poor students.
                      </strong>
                      </li>
                    </ul>
                  </p>
                  
                  <div className="freecourse-download-form">
                    <div className="row max-mb-n20">
                      <div className="col-md-12 max-mb-20 disclaimer">
                        <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={() => {
                            if (other) {
                              setShow(true);
                            } else {
                              assignteacher();
                              setTeacherprocess(true);
                            }
                          }}
                          disabled={show}
                        >
                          Agree
                        </button>
                        <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={() => closepop()}
                          disabled={show}
                        >
                          Disagree
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {show && (
              <div className="row">
                <div className="col-md-12 col-12 align-self-center">
                  <div className="freecourse-popup-content m-4">
                    <h6>You want to be a</h6>
                    <div className="d-flex align-center justify-content-around">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={"memoriser"}
                          checked={memoriser == "memoriser"}
                          onChange={(e) => {
                            handleChange1(e);
                            // setMemoriser(e.target.value);
                          }}
                          defaultChecked={true}
                        // name="flexRadioDefault"
                        // id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Memoriser
                        </label>
                      </div>
                      <div className="form-check ml-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={"fluentreader"}
                          checked={memoriser == "fluentreader"}
                          onChange={(e) => handleChange1(e)}
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          defaultChecked={false}
                        // defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Fluent reader
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="freecourse-download-form">
                    <div className="row max-mb-n20">
                      <div className="col-md-12 max-mb-20 disclaimer">
                        <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={() => handleContinue()}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}

            {show && (
              <div className="row">
                <div className="col-md-12 col-12 align-self-center">
                  <div className="freecourse-popup-content m-4">
                    <h6>Language preference for the Gita books with a drop-down list of Telugu or English or Sanskrit</h6>
                    <div className="d-flex align-center justify-content-around">
                      {languageOptions.map((option, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value={option}
                            checked={memoriser === option}
                            onChange={(e) =>
                              handleChange1(e)}
                            disabled={!editingEnabled}
                          />
                          <label className="form-check-label" htmlFor={`flexRadioDefault${index + 1}`}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="freecourse-download-form">
                    <div className="row max-mb-n20">
                      <div className="col-md-12 max-mb-20 disclaimer">
                        <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={() => handleContinue()}
                          disabled={
                            (memoriser && continueEditingEnabled == true) ? true : false
                          }
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {teacherprocess && (
              <h1 style={{ color: "#54CD22 " }}>
                <b>successfully selected slot for the course</b> <br />
              </h1>
            )}
          </div>
        );

      default:
        return "Unknown step";
    }
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  if (loading) {
    return (
      <div
        className="loader"
        style={{
          minHeight: "90vh",
          minWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="loader"
          style={{
            minHeight: "10vh",
          }}
        />
      </div>
    );
  }
  console.log("owerview-----", overviewdata)
  return (
    <div>
      <h4 className="duecls">
        {checklockstatus
          ? ""
          : "Currently this account is deactivated, please pay the payment and activate it!!"}
      </h4>
      <div id="page">
        {/* <div> */}
        {/* Page Banner Section Start */}
        {/* {localImg.filter((val) => {
          if (val.id == id) {
            demo = val.img;
            // console.log(val.img, "AAAAAAAAAA");
          }
        })} */}
        {/* {console.log(demo, "DDDDDDDDDD")}
        {/* {console.log(Img2 + "**************")} */}
        <div
          // style={{
          //   backgroundImage: `url(${demo})`,
          // }}
          className="page-banner-section section overlay section-padding"
          data-bg-image="./assets/images/page-banner/course-bg.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-banner-title course-banner-title">
                  <h1 className="title title-tra">
                    {overviewdata?.course_name}
                  </h1>
                </div>
                <div className="page-title-bar-meta">
                  <div className="course-duration">
                    {/* <span className="meta-icon far fa-clock" /> */}
                    <span className="meta-value">
                      {/* {overviewdata?.course_duration} */}
                      {overviewdata?.type !== null
                        ? overviewdata?.type != "" &&
                        "( " + overviewdata?.type + " )"
                        : ""}
                    </span>
                  </div>
                  {/* <div className="course-lesson">
                    <span className="meta-icon far fa-file-alt" />
                    <span className="meta-value">
                      {localStorage.getItem("lessons")} Lessons
                    </span>
                  </div>
                  <div className="course-students">
                    <span className="meta-icon far fa-user-alt" />
                    <span className="meta-value">
                      {localStorage.getItem("students")} Students
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Banner Section End */}
        {/* Page Title Section Start */}
        <div className="page-title-section section section-padding-top-0 section-padding-bottom-120">
          <div className="page-breadcrumb">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Course Details</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Page Title Section End */}
        {/* Course Details Section Start */}
        <div className="section max-mb-40">
          <div className="container">
            <div className="row max-mb-n50">
              <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
                {/* Course Details Wrapper Start */}
                <div className="course-details-wrapper align-cont">
                  <div className="course-nav-tab">
                    <ul className="nav">
                      <li>
                        <a
                          className="active"
                          data-bs-toggle="tab"
                          href="#overview"
                          onClick={() => showglimpse()}
                          ref={ref}
                          id="myDIV"
                        // className={(localStorage.getItem('instrcutertab')==='true')? '':'active'}
                        >
                          Overview
                        </a>
                      </li>
                      {paystatus === "success" ||
                        paystatus === "pending" ||
                        paystatus === "half" ? (
                        <li>
                          <a
                            data-bs-toggle="tab"
                            href="#curriculum"
                            onClick={() => hideglimpse()}
                          >
                            Curriculum
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <a
                          data-bs-toggle="tab"
                          href="#instructor"
                          onClick={() => hideglimpse()}
                          ref={instroctorRef}
                        // class={(localStorage.getItem('instrcutertab')==='true')? 'active':''}
                        >
                          Instructors
                        </a>
                      </li>
                      <li>
                        {localReviewIds.map((val) => {
                          if (val.id == overviewdata?.course_id) {
                            return (
                              <a
                                data-bs-toggle="tab"
                                href="#reviews"
                                onClick={() => hideglimpse()}
                              >
                                Reviews
                              </a>
                            );
                          } else {
                            return null;
                          }
                        })}
                        {/* <a
                          data-bs-toggle="tab"
                          href="#reviews"
                          onClick={() => hideglimpse()}
                        >
                          Reviews
                        </a> */}
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div id="overview" className="tab-pane fade show active">
                      <div className="course-overview">
                        {localStorage.getItem("uid") && (paystatus == "full" || paystatus == "half" || paystatus == "success") ?
                          (
                            <div >

                              {/* {  JSON.parse(overviewdata?.note || '{}')} */}
                              {(overviewdata?.memar || overviewdata?.note) && (

                                <div className="related-courses-section section sec-padding align-cont add-info-high">
                                  <h3 className="title add-high">Additional Information</h3>


                                  {/* <div className="m-f-up  max-mt-20 max-mb-20">

                                    {overviewdata?.memar ? (
                                      <div className="form-check m-f-op">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value={"memoriser"}
                                          defaultChecked={overviewdata?.memar == "memoriser"}
                                          onChange={(e) => {
                                            handleChange1(e);
                                            // setMemoriser(e.target.value);
                                          }}
                                          name="flexRadioDefault22"
                                          id="flexRadioDefault22"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault22"
                                        >
                                          Memoriser
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {overviewdata?.memar ? (
                                      <div className="form-check  m-f-op ml-4">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value={"fluentreader"}
                                          defaultChecked={overviewdata?.memar == "fluentreader"}
                                          onChange={(e) => handleChange1(e)}
                                          name="flexRadioDefault22"
                                          id="flexRadioDefault22"
                                        // defaultChecked
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault22"
                                        >
                                          Fluent reader
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div> */}

                                  <div className="m-f-up  max-mt-20 max-mb-20">
                                    {console.log("get", overviewdata?.memar)}

                                    {overviewdata?.memar ? (
                                      <div className="form-check m-f-op">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value={"Telugu"}
                                          defaultChecked={overviewdata?.memar == "Telugu"}
                                          onChange={(e) => {
                                            handleChange1(e);
                                            // setMemoriser(e.target.value);
                                          }}
                                          name="flexRadioDefault22"
                                          id="flexRadioDefault22"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault22"
                                        >
                                          Telugu
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {overviewdata?.memar ? (
                                      <div className="form-check  m-f-op ml-4">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value={"English"}
                                          defaultChecked={overviewdata?.memar == "English"}
                                          onChange={(e) => handleChange1(e)}
                                          name="flexRadioDefault22"
                                          id="flexRadioDefault22"
                                        // defaultChecked
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault22"
                                        >
                                          English
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}


                                    {overviewdata?.memar ? (
                                      <div className="form-check  m-f-op ml-4">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value={"Sanskrit"}
                                          defaultChecked={overviewdata?.memar == "Sanskrit"}
                                          onChange={(e) => handleChange1(e)}
                                          name="flexRadioDefault22"
                                          id="flexRadioDefault22"
                                        // defaultChecked
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault22"
                                        >
                                          Sanskrit
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {overviewdata?.memar ? (
                                    <div className="col-md-12 max-mb-20 disclaimer">
                                      <button
                                        className="btn btn-primary btn-hover-secondary m-f-btn"
                                        onClick={() => handleUpdate()}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {/* {overviewdata?.note && parse(overviewdata?.note)} */}
                                  {overviewdata && !Array.isArray(overviewdata) &&
                                    parse(
                                      JSON.parse(overviewdata?.note)?.note
                                    )}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        <h3 className="title">Course Description</h3>
                        <p>
                          {/* {overviewdata?.course_description.split("href=","")} */}
                          {overviewdata?.course_description &&
                            parse(
                              JSON.parse(overviewdata?.course_description)
                                .description
                            )}
                        </p>

                        {/* <div className="overview-course-video">
                          <iframe
                            title="jQuery Tutorial #1 - jQuery Tutorial for Beginners"
                            src="https://www.youtube.com/embed/hMxGhHNOkCU?feature=oembed"
                          />
                        </div> */}
                        {!!overviewdata?.what_we_learn &&
                          JSON.parse(overviewdata?.what_we_learn).what !==
                          "" && <h3 className="title">What we learn?</h3>}

                        {/* //? console.log(overviewdata?.what_we_learn?.what,"!!!!!!!!!!!!!!!!!!!!!!!!!!!!11") : (
                        //  <h3 className="title">what we learn{console.log(overviewdata?.what_we_learn?.what,"NNNNNNNNNNNNNNNNNNNNNN")}</h3>
                       // )} */}

                        <p>
                          {overviewdata?.what_we_learn &&
                            parse(JSON.parse(overviewdata?.what_we_learn).what)}
                        </p>
                      </div>
                    </div>
                    <div id="curriculum" className="tab-pane fade">
                      <div className="course-curriculum">
                        <div id="accordion" className="g-sec">
                          {curriculamdata?.map((res, i) => {
                            let chapterdata = res?.total_topics;
                            let weekname = chapterdata[0].weekCount.slice(0, 7);
                            weekname =
                              weekname.charAt(0).toUpperCase() +
                              weekname.slice(1);
                            let show_str = i == 0 ? "show" : "";

                            return (
                              <ul className="curriculum-sections">
                                <li className="single-curriculum-section">
                                  <div
                                    className="section-header"
                                    style={{ pointer: "cursor" }}
                                  >
                                    <div
                                      className="section-left"
                                      class="card course g-info   collapsed"
                                      data-toggle="collapse"
                                      href={"#collapseOnes" + i}
                                    >
                                      <h5 className="title">{weekname}</h5>
                                    </div>
                                  </div>

                                  <div
                                    id={"collapseOnes" + i}
                                    class={"collapse" + show_str}
                                    data-parent="#accordion"
                                  >
                                    <div className="row cur-lab">
                                      <div className="col-md-6 t-n-l">
                                        Topic Name
                                      </div>
                                      <div className="col-md-3 t-n-l-t">
                                        Home Work
                                      </div>
                                      <div className="col-md-3 t-n-l-t">
                                        Topic Content
                                      </div>
                                    </div>
                                    <ul className="section-content">
                                      {chapterdata?.map((cdata, index) => {
                                        let add = index == 0 ? 1 : index;
                                        totalrecords += add;
                                        return (
                                          <li className="course-item">
                                            <a
                                              className="section-item-link lesson"
                                              href="JavaScript:Void(0);"
                                            >
                                              <div className="row cur-cont">
                                                <div className="col-md-6">
                                                  {" "}
                                                  <span className="item-name topic-ite">
                                                    {cdata.topicName
                                                      ? cdata.topicName
                                                      : ""}
                                                  </span>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="item-name topic-ite">
                                                    {cdata.homework == "true"
                                                      ? "Yes"
                                                      : "No"}
                                                  </span>
                                                </div>
                                                <div className="col-md-3">
                                                  {checklockstatus ? (
                                                    <div className="course-item-meta">
                                                      {cdata?.pdf ? (
                                                        <span className="item-meta item-meta-icon pdf">
                                                          <a
                                                            href={cdata?.pdf}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            <i class="far fa-file-pdf"></i>
                                                          </a>
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <span className="item-meta item-meta-icon video">
                                                        <a
                                                          href={cdata?.video}
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          <i className="far fa-video" />
                                                        </a>
                                                      </span>

                                                      <span className="item-meta item-meta-icon zoom-meeting">
                                                        <i className="far fa-users-class" />
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="course-item-meta">
                                                      <span
                                                        className="item-meta item-meta-icon zoom-meeting"
                                                        title="payment required"
                                                      >
                                                        <i
                                                          className="fas fa-lock-alt"
                                                          onClick={(e) =>
                                                            paymentalert()
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div id="instructor" className="tab-pane fade ">
                      {teachers.length > 0 ? (
                        teachers.map((teacher, i) => {
                          if (
                            assignteacherid === teacher?.user_id &&
                            assignclassid === teacher.class_name
                          ) {
                            var checked_str = true;
                          } else {
                            var checked_str = false;
                          }

                          if (assignteacherid > 0) {
                            var disabled_str = true;
                          } else {
                            var disabled_str = false;
                          }

                          return (
                            <div className="course-instructor t-sec">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="profile-image t-prof">
                                    <img
                                      src={teacher?.image}
                                      alt="instructor"
                                    />
                                    <div className="c-content t-a-s">
                                      <div>Available slots</div>
                                      <div className="c-values a-slots">
                                        {teacher.available_slots}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="profile-info t-p-cnt">
                                    <div class="t-slot-sec">
                                      <h4 class="mg-b-10">
                                        {teacher?.fullname}
                                      </h4>
                                      {teacher?.available_slots > 0 ? (
                                        <div className="pick-t">
                                          {paystatus == "success" ||
                                            paystatus == "pending" ||
                                            paystatus == "half" ? (
                                            <label for="login-form-remember">
                                              {disabled_str && checked_str
                                                ? "Assigned"
                                                : paystatus
                                                  ? "Pick the teacher"
                                                  : ""}
                                            </label>
                                          ) : null}
                                          {paystatus == "success" ||
                                            paystatus == "pending" ||
                                            paystatus == "half" ? (
                                            <input
                                              type="checkbox"
                                              checked={
                                                !disabled_str
                                                  ? checkvalue.includes(
                                                    teacher?.user_id
                                                  )
                                                  : checked_str
                                              }
                                              disabled={disabled_str}
                                              name={
                                                "pick_teacher_" +
                                                teacher?.user_id
                                              }
                                              id={
                                                "pick_teacher_" +
                                                teacher?.user_id
                                              }
                                              onClick={(e) => {
                                                pickTeacher(
                                                  e,
                                                  teacher?.user_id,
                                                  teacher?.class_name
                                                );
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : assignteacherid > 0 &&
                                        assignteacherid == teacher?.user_id ? (
                                        <>
                                          {" "}
                                          <div className="pick-t">
                                            <label for="login-form-remember">
                                              {disabled_str && checked_str
                                                ? "Assigned"
                                                : paystatus
                                                  ? "Pick the teacher"
                                                  : ""}
                                            </label>
                                            <input
                                              type="checkbox"
                                              checked={
                                                !disabled_str
                                                  ? checkvalue.includes(
                                                    teacher?.user_id
                                                  )
                                                  : checked_str
                                              }
                                              disabled={disabled_str}
                                              name={
                                                "pick_teacher_" +
                                                teacher?.user_id
                                              }
                                              id={
                                                "pick_teacher_" +
                                                teacher?.user_id
                                              }
                                              onClick={(e) => {
                                                pickTeacher(
                                                  e,
                                                  teacher?.user_id,
                                                  teacher?.class_name
                                                );
                                              }}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <div className="pick-t">
                                          <label for="login-form-remember">
                                            <span style={{ color: "red" }}>
                                              Slots not available
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </div>
                                    <div className="t-h-t">
                                      <p className="c-time">
                                        Class Time:{" "}
                                        <span>
                                          {teacher.slot_time
                                            .split(",")
                                            .map((time, i) => (
                                              <>
                                                {(i === 0 ? "" : ", ") +
                                                  moment(time)
                                                    .clone()
                                                    .tz(moment.tz.guess())
                                                    .format("dddd hh:mm A z")}
                                              </>
                                            ))}
                                        </span>
                                      </p>
                                    </div>

                                    <p>
                                      {parse(teacher?.description) ||
                                        "Lorem Ipsum is simply dummy text of the"}
                                    </p>
                                    <h6>Details</h6>
                                    {/* <p>
                                      DOB:{" "}
                                      <span>
                                        {teacher?.dob || "27/FEB/1980"}
                                      </span>
                                    </p> */}
                                    {/* <p>
                                      Association Duration:{" "}
                                      <span>
                                        {teacher?.associated_from} years
                                      </span>
                                    </p> */}
                                    <p>
                                      Courses:
                                      <span> {teacher?.courses}</span>
                                    </p>
                                    {/* <div className="mg-t-15 t-contact">
                                      <p>
                                        <i class="fas fa-mobile-alt"></i>
                                        <span>
                                          {teacher.phone_number ||
                                            "040-2713 2550"}
                                        </span>
                                      </p>
                                      <p>
                                        <i class="far fa-envelope t-mail"></i>
                                        {
                                          <span>
                                            {teacher.email_id ||
                                              "info@rushipeetham.org"}
                                          </span>
                                        }
                                      </p>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h1 style={{ color: "red" }}>"No Data available"</h1>
                      )}
                    </div>
                    <div id="reviews" className="tab-pane fade">
                      <div className="course-reviews">
                        {/* Jagadguru start */}
                        {overviewdata?.course_name === "Jagadguru" && (
                          <div className="course-reviews-area">
                            <ul className="course-reviews-list">
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Sudha B</h4>
                                    <div className="review-text">
                                      Very enlightening experience. What seemed
                                      like an impossible task was made possible
                                      by this program by the methodology adopted
                                      for teaching the divine Bhagavad Gita.
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Aruna Rambhatla</h4>
                                    <div className="review-text">
                                      The program is second to none. The
                                      dedication with which the teacher has
                                      taught the course is of the highest
                                      standard. Pronunciation is observed keenly
                                      and corrected where necessary. The rules
                                      of word division and combination are also
                                      told. Class timings are well communicated
                                      and regularity is thoroughly maintained.
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Pushya Pelluri</h4>
                                    <div className="review-text">
                                      With Learn Gita program, now I know
                                      Bhagavad Gita slokas meaning which helped
                                      in shaping my personality. It helped me to
                                      handle day to day life in a balanced and
                                      conflicts-free way. With the enhanced
                                      focus on pronunciation, when I am reciting
                                      any other stotram or sahasranamam, I am
                                      reciting correctly and those stotras are
                                      sounding very beautiful now. There is no
                                      age limit, no boundary to learn Gita. I am
                                      working and have a 4 year old boy. No home
                                      maker or maid at home. Been in the Learn
                                      Gita program for 6 months now. I am only
                                      saying this to give energy to all those
                                      who are just like me and wondering if they
                                      have time to learn. Yes you do!
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">
                                      Sailaja Kanchibhotla
                                    </h4>
                                    <div className="review-text">
                                      Learning Gita has been a great blessing to
                                      my family. My 11 year old son, Sriram and
                                      I learn and chant slokas together and
                                      discuss the meanings. This creates a
                                      beautiful environment in the house. With
                                      Guru Krupa we want to learn the entire
                                      Bhagavad Gita.
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Suneetha K</h4>
                                    <div className="review-text">
                                      Hearty congratulations to all the readers,
                                      especially to the speakers who were able
                                      to do so in the presence of Guruvu garu.
                                      All are blessed. I can totally visualize
                                      Lot enormous amount if energy and positive
                                      vibrations (during chanting).
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                        {/* Jagadguru end */}

                        {/* Geethacharya start */}
                        {overviewdata?.course_name === "Geetacharya" && (
                          <div className="course-reviews-area">
                            <ul className="course-reviews-list">
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">
                                      Kartik Vijayashanker
                                    </h4>
                                    <div className="review-text">
                                      As common people we are not so capable to
                                      learn the vedas and the shastras but we
                                      have been born a blessed human birth and
                                      being a Hindu we should know Gita!
                                      Rushipeetam has provided a unique
                                      opportunity to learn from qualified and
                                      committed teachers ,which we hardly get.I
                                      personally believe unless we have a
                                      praptam and sukrutam we will not get to
                                      learn from a teacher which is a unique and
                                      needed experience. So we should make full
                                      use of this opportunity and make this
                                      human birth worth what it is meant for. In
                                      the end I would like to express my
                                      gratitude to sri Guru garu, my adhyapika
                                      Shanti garu who’s commitment to teach and
                                      love for her students is unparalleled, and
                                      the whole team whole made this online
                                      learning a seamless and joyful experience
                                      right from preparing learning materials to
                                      managing logistics a words can’t thank one
                                      and all.
                                      <br />
                                      My heartfelt gratitude to one and all for
                                      making this learning such a joyful
                                      experience. The effort which goes into
                                      logistics and making this happen just
                                      cannot be thanked in words. We remain
                                      forever indebted to each and everyone in
                                      the team.
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Srujana</h4>
                                    <div className="review-text">
                                      Blessed to be part of this course. One can
                                      learn many things from this course, even
                                      though if you are a fluent reader there is
                                      a lot to know. Definitely this is a unique
                                      program where you can understand each
                                      word's meaning from material and depth of
                                      the entire Bhagavad gita from Guruvugari
                                      pravachanams.
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                        {/* Geethacharya end */}

                        {/* Akshara Bhrama start */}

                        {overviewdata?.course_name === "Akshara Brahma" && (
                          <div className="course-reviews-area">
                            <ul className="course-reviews-list">
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Surekha Ayyagari</h4>
                                    <div className="review-text">
                                      I'm a very recent and nascent student of
                                      the Gita lessons.These lessons have indeed
                                      given me a different perspective on life,
                                      living and beyond. Each of these chapters
                                      intrigued me and drove me to examine every
                                      aspect of my day to day life and
                                      contemplate. Slowly this helped me
                                      understand and assimilate that though I am
                                      just a speck in the bigger scheme of
                                      things yet I'm part and parcel and always
                                      one with the Supreme Energy! I'm part of
                                      everything and everything is part of me!
                                      This humbles me and makes me more
                                      responsible and most importantly it gives
                                      me the immense clarity to view everything
                                      I have and experience; with greater
                                      reverence and gratitude.
                                      <br />
                                      Everyday when I wake up , the line which
                                      greets me and keeps me grounded is
                                      “Nimmita matram bhava savyasachin “
                                      <br />
                                      I'm grateful to Guruvugaru for his
                                      comprehensive and eloquent pravachanams
                                      which are steeped in deep and vast
                                      knowledge yet relatable and enjoyable to a
                                      novice like me. Pranams
                                      <br />
                                      I'm very grateful to Vidya -my teacher and
                                      mentor who brought me to this. Her
                                      dedication and patience always inspire me.
                                      <br />
                                      Thank you for putting me on this path
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="review">
                                <div className="review-container">
                                  <div className="review-author">
                                    <img
                                      src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                      alt="author"
                                    />
                                  </div>
                                  <div className="review-content">
                                    <h4 className="title">Theja</h4>
                                    <div className="review-text">
                                      I'm fortunate to join Rushipeetham
                                      Bhagavad Gita classes. It helps in
                                      handling every situation of my life both
                                      personally and
                                      professionally...Instructors motivate me
                                      everyday. It helps me in analysing and
                                      understanding what life is all about.
                                      Thank you very much for the Rushipeetham
                                      team in organizing these classes.
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                        {/* Akshara Bhrama end */}
                        {/* {(overviewdata?.course_name != "Akshara Bhrama" && overviewdata?.course_name != "Geethacharya"  && overviewdata?.course_name != "Jagadguru") &&
                        <div><h4 className="title" style={{ color: "red" }}>No Reviews found</h4></div>

                        } */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Course Details Wrapper End */}
              </div>
              <div className="col-lg-4 col-12 order-lg-2 max-mb-50">
                <div className="pl-30 pl-md-0 pl-sm-0 pl-xs-0">
                  <div className="sidebar-widget">
                    <div className="col-md-12">
                      <div className="c-profile">
                        <img
                          alt="not loaded"
                          src={overviewdata?.course_image}
                        ></img>
                        {overviewdata?.price ?
                          <div className="c-content">
                            <div>Price</div>
                            <div className="c-values">
                              {overviewdata?.price && overviewdata?.price} /
                              {overviewdata?.course_id == 64397396
                                ? "person"
                                : overviewdata?.course_duration}
                              {/* {data?.[4].map((item) => {
                              return (
                                <section className="price-sec">
                                  <span className="region-name">
                                    {item.country_name} -
                                  </span>
                                  <span className="c-price">
                                    {item.price_value}
                                  </span>
                                </section>
                              );
                            })} */}
                            </div>
                          </div> :
                          null
                        }
                        <div className="c-content">
                          <div>Duration</div>
                          <div className="c-values title-tra">
                            {overviewdata?.course_duration}
                          </div>
                        </div>
                        <div className="c-content">
                          <div>Start date</div>
                          <div className="c-values">
                            {overviewdata?.start_date}
                          </div>
                        </div>
                        <div className="c-content">
                          <div>Teacher count</div>
                          <div className="c-values">
                            {overviewdata?.course_id == 97663598
                              ? "45"
                              : data?.[2]?.length || "00"}
                          </div>
                        </div>
                        {/* <div className="c-content">
                          <div>Students Enrolled</div>
                          {console.log(data?.[0]?.[0]?.student_enrolled)}
                          <div className="c-values">
                            {overviewdata?.student_enrolled}
                          </div>
                        </div> */}
                        <div className="buy-c">
                          <div
                            className="c-content"
                            style={{ justifyContent: "center" }}
                          >
                            {console.log(
                              paystatus,
                              "paystatusssssssssssssssssssssssss"
                            )}
                            <button
                              className="btn btn-primary btn-hover-secondary"
                              onClick={() => {
                                if (
                                  localStorage.getItem("uid") &&
                                  overviewdata?.course_id != 97663598
                                ) {
                                  setPopuptext1("fadeInUp");
                                  setpopupclose1("");
                                } else if (
                                  overviewdata?.course_id == 97663598
                                ) {
                                  window.open("https://www.dhruvajnanam.org/");
                                } else {
                                  toast("Please Login to Enroll");
                                  navigate("/login");
                                }
                              }}
                              disabled={
                                overviewdata?.reg_status === "opened" &&
                                  paystatus === "failed"
                                  ? false
                                  : paystatus === "success" ||
                                    paystatus === "half"
                                    ? "disabled"
                                    : !dummy
                                      ? paystatus === "pending"
                                        ? false
                                        : "disabled"
                                      : "disabled"
                              }
                            >
                              {overviewdata?.reg_status === "opened" &&
                                paystatus === "failed"
                                ? "Register"
                                : paystatus === "success" ||
                                  paystatus === "half"
                                  ? "Registered"
                                  : !dummy
                                    ? paystatus === "pending"
                                      ? "Pay Now"
                                      : "Registrations Closed" // enable regi
                                    : "Please wait..."}
                              {/* {paystatus == 'success'
                                ? 'Registered'
                                : !dummy
                                ? overviewdata?.course_id == 55781742 ||
                                  overviewdata?.course_id == 64397396
                                  ? 'Registrations Closed'
                                  : 'Register'
                                : 'Please wait...'} */}
                            </button>
                          </div>
                          {paystatus == "half" && (
                            <>
                              <div
                                className={`c-content ${checklockstatus ? "" : "duecls"
                                  }`}
                              >
                                <div>Next Due Date </div>
                                <div
                                  className={`c-values ${checklockstatus ? "" : "duecls"
                                    }`}
                                >
                                  {duedate}
                                </div>
                              </div>
                              <div
                                className={`c-content pay-b ${checklockstatus ? "" : "duecls"
                                  }`}
                              >
                                <div>Due Amount</div>
                                <div
                                  className={`c-values  ${checklockstatus ? "" : "duecls"
                                    }`}
                                >
                                  {`${dueamount} ${window.localStorage
                                    .getItem("price")
                                    .substr(-3)}`}{" "}
                                  &nbsp;
                                  <a
                                    onClick={() => {
                                      setActiveStep(2);
                                      setPopuptext1("fadeInUp");
                                      setpopupclose1("");
                                    }}
                                  >
                                    Pay Now
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Course Details Section End */}
        {/* Related Courses Section Start */}
        {/* Related Courses Section End */}
        {/* </div> */}
        {/* Newsletter Popup Start */}
        <div
          id="max-popup"
          className={"max-popup-section section  " + popupclose}
        // style={{ maWidth: "50vw important" }}
        >
          <div className={"max-popup-dialog animated " + popuptext}>
            {!show && (
              <button className="max-popup-close" onClick={() => closepop()}>
                <i className="fal fa-times" />
              </button>
            )}
            <div className="max-popup-dialog-inner">
              {!show && (
                <div className="row">
                  <div className="col-md-12 col-12 align-self-center">
                    <div className="freecourse-popup-content">
                      <h6>Disclaimer</h6>
                      <p className="title1">
                        Due to unforeseen circumstances if a teacher goes on
                        leave of absence, Rushividyabhyasam team reserves the
                        right to assign the impacted students to other classes
                        or assign a new teacher to the class.
                      </p>
                      <div className="freecourse-download-form">
                        <div className="row max-mb-n20">
                          <div className="col-md-12 max-mb-20 disclaimer">
                            <button
                              className="btn btn-primary btn-hover-secondary"
                              onClick={() => {
                                if (other) {
                                  setShow(true);
                                } else {
                                  assignteacher();
                                }
                              }}
                            >
                              Agree
                            </button>
                            <button
                              className="btn btn-primary btn-hover-secondary"
                              onClick={() => closepop()}
                            >
                              Disagree
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {show && (
                <div className="row">
                  <div className="col-md-12 col-12 align-self-center">
                    <div className="freecourse-popup-content m-4">
                      <h6>You want to be a</h6>
                      <div className="d-flex align-center justify-content-around">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"memoriser"}
                            checked={memoriser == "memoriser"}
                            onChange={(e) => {
                              handleChange1(e);
                              // setMemoriser(e.target.value);
                            }}
                            defaultChecked={true}
                          // name="flexRadioDefault"
                          // id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Memoriser
                          </label>
                        </div>
                        <div className="form-check ml-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"fluentreader"}
                            checked={memoriser == "fluentreader"}
                            onChange={(e) => handleChange1(e)}
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked={false}
                          // defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Fluent reader
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="freecourse-download-form">
                      <div className="row max-mb-n20">
                        <div className="col-md-12 max-mb-20 disclaimer">
                          <button
                            className="btn btn-primary btn-hover-secondary"
                            onClick={() => handleContinue()}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // </div>
              )} */}

              {show && (
                <div className="row">
                  <div className="col-md-12 col-12 align-self-center">
                    <div className="freecourse-popup-content m-4">
                      <h6>Language preference for the Gita books with a drop-down list of Telugu or English or Sanskrit</h6>
                      <div className="d-flex align-center justify-content-around">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"Telugu"}
                            checked={memoriser == "Telugu"}
                            onChange={(e) => {
                              handleChange1(e);
                              // setMemoriser(e.target.value);
                            }}
                            defaultChecked={false}
                          // name="flexRadioDefault"
                          // id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Telugu
                          </label>
                        </div>

                        <div className="form-check ml-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"English"}
                            checked={memoriser == "English"}
                            onChange={(e) => handleChange1(e)}
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked={false}
                          // defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            English
                          </label>
                        </div>


                        <div className="form-check ml-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"Sanskrit"}
                            checked={memoriser == "Sanskrit"}
                            onChange={(e) => handleChange1(e)}
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked={false}
                          // defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Sanskrit
                          </label>
                        </div>


                        <div className="form-check ml-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"No Language Selected"}
                            checked={memoriser == "No Language Selected"}
                            onChange={(e) => handleChange1(e)}
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked={false}
                          // defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            No Language Selected
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="freecourse-download-form">
                      <div className="row max-mb-n20">
                        <div className="col-md-12 max-mb-20 disclaimer">
                          <button
                            className="btn btn-primary btn-hover-secondary"
                            onClick={() => handleContinue()}
                            disabled={
                              (memoriser && continueEditingEnabled == true) ? true : false
                            }
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // </div>
              )}
            </div>
          </div>
        </div>

        {glimpsestatus ? (
          glimpses.length > 0 ? (
            <Glimpsecard glimpsArray={glimpses} />
          ) : null
        ) : (
          ""
        )}
        {/* Related courses */}
        {relatedCourse.length > 0 ? (
          <Relatedcourses data={relatedCourse} />
        ) : null}
      </div>

      {/* modal */}

      <div
        id="max-popup"
        className={"max-popup-section section  " + popupclose1}
      >
        <div
          className={"max-popup-dialog animated " + popuptext1}
          style={{ width: "100%" }}
        >
          <button
            className="max-popup-close"
            disabled={activeStep > 0 && teacherprocess && paystatus != "half"}
            onClick={() => {
              setPopuptext1("fadeOutUp");
              setpopupclose1("close");
              if (activeStep !== 2) {
                setActiveStep(0);
              }

              setShow(false);
              setMemoriser(false);

              //console.log(paystatus, "paystatus paystatus == 'half'")
              if (paystatus != "pending") {
                if (assignteacherid == null && paystatus != "failed") {
                  setActiveStep(0);
                }
                setTeacherid(null);
                setClassid(null);
              }
            }}
          >
            <i className="fal fa-times" />
          </button>
          <div
            className="max-popup-dialog-inner"
            style={{ width: "100%", height: "700px" }}
          >
            <div className="row">
              <div className="col-md-12 col-12 align-self-center">
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};

                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : activeStep == 0 ? (
                    <React.Fragment>
                      <div>{getStepContent(activeStep)}</div>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        className="nx-btn"
                      >
                        {/* <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button> */}
                        {console.log(activeStep, "active stepppppppp")}
                        <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={handleNext}
                          disabled={teacherid == null}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </button>
                      </Box>
                    </React.Fragment>
                  ) : activeStep == 1 ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        <div>{getStepContent(activeStep)}</div>
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        className="nx-btn"
                      >
                        {/* <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button> */}

                        <button
                          className="btn btn-primary btn-hover-secondary"
                          onClick={handleNext}
                          disabled={!teacherprocess}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </button>
                      </Box>
                    </React.Fragment>
                  ) : activeStep == 2 ? (
                    <React.Fragment>
                      {/* {alert("hiii")} */}
                      <div>{getStepContent(activeStep)}</div>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        className="nx-btn"
                      >
                        {/* <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button> */}

                        {activeStep == 2 && paymentStatus && (
                          <button
                            className="btn btn-primary btn-hover-secondary"
                            onClick={handleNext}
                            disabled={!paymentStatus}
                          >
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : "Next"}
                          </button>
                        )}
                      </Box>
                    </React.Fragment>
                  ) : null}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
