import React, { useEffect, useState } from 'react'
import Axios from '../utilities/Axios'
import { uploadFile } from 'react-s3'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { formatDate } from '../utilities/convert'
import { currency } from '../utilities/GlobalCurrency'
import CryptoJS from 'crypto-js'
import { useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import RichTextEditor from 'react-rte'
import parse from 'html-react-parser'
import { data } from 'jquery'
const S3_BUCKET = 'rushi2023'
const REGION = 'ap-south-1'
const ACCESS_KEY = 'AKIAZIHUNM34ZPGBCTWR'
const SECRET_ACCESS_KEY = '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'

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  dirName: 'folder',
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}
var today = new Date()
var dd = String(today.getDate()).padStart(2, '0')
var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
var yyyy = today.getFullYear()

today = yyyy + '-' + mm + '-' + dd

const MyAccount = () => {
  const navigate = useNavigate()
  const imageClicked = (id) => {
    navigate(`/coursedetails/${id}`)
  }
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    description: '',
    image: '',
    gender: '',
    dob: today,
    address: '',
    pincode: '',
    city: '',
    state: '',
    region: '',
    preference_name: "Language",
    preference_value: ""
  })
  const [dummydes, setDummydes] = React.useState(
    RichTextEditor.createEmptyValue(),
  )
  const [isUpdated, setIsUpdated] = useState(
    localStorage.getItem('isUpdated') === 'true',
  )

  // alert(isUpdated);
  const [ordersdata, setOrdersdata] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorfname, setErrorfname] = useState(false)
  const [errorlname, setErrorlname] = useState(false)
  const [errorgender, setErrorgender] = useState(false)
  const [errorLang, setErrorLang] = useState(false)
  const [errordob, setErrordob] = useState(false)
  const [errormobi, setErrormobi] = useState(false)
  const [erroraddress, setErroraddress] = useState(false)
  const [errorcity, setErrorcity] = useState(false)
  const [errorstate, setErrorstate] = useState(false)
  const [errorpin, setErrorpin] = useState(false)
  const [errorpincode, setErrorPincode] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [waitstatus, setWaitstatus] = React.useState(false)
  const [pwdwaitstatus, setPwdwaitstatus] = React.useState(false)
  const [showpwd, setShowpwd] = React.useState(false)
  const [showcpwd, setShowcpwd] = React.useState(false)
  const [showcnfpwd, setShowcnfpwd] = React.useState(false)
  const [object, setObject] = React.useState({
    old_password: '',
    new_password: '',
  })
  const [values, setValues] = React.useState({
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
    showOldPassword: false,
  })

  const [reg, setRegion] = React.useState(false)


  const showpassword = () => {
    setShowpwd(!showpwd)
  }

  const showcpassword = () => {
    setShowcpwd(!showcpwd)
  }
  const showcnfpassword = () => {
    setShowcnfpwd(!showcnfpwd)
  }
  //const navigate = useNavigate();
  const handleUploadImage = async (file) => {
    // console.log("user", user);
    await uploadFile(file, config)
      .then((data) => {
        if (data?.result?.ok) {
          handleUpdate(data?.location)
        } else {
          alert('error')
        }
      })
      .catch((err) => handleUpdate(user?.image))
  }
  const handleChange = (e) => {
    console.log("lll", e.target.value)

    setUser({ ...user, gender: e.target.value })

  }


  const handleChangePreferenece = (e) => {
    console.log("lll", e.target.value)
    if (e.target.value == "") {
      setErrorLang(true)
    }
    setUser({
      ...user,
      preference_value: e.target.value,
      preference_name: "Language",
    })

  }
  const handleChangereg = (e) => {
    setUser({ ...user, region: e.target.value })
  }
  useEffect(() => {
    let uid = localStorage.getItem('uid')
    Axios.get(`user_details/get_user_details/${uid}`)
      .then((res) => {
        let user = res?.data?.data[0]
        // console.log("user", user);
        console.log('=============uuuuuuuuuuuuuuuuuuuuuuuuuuu=======================')
        console.log(user)
        console.log('====================================')
        if (user.region !== "") {
          setRegion(true)
        }

        if (!user?.gender || !user?.region) {
          toast('Please Update Profile to continue', {
            type: 'info',
            theme: 'dark',
          })
        }
        setUser(user)
        setDummydes(
          RichTextEditor.createValueFromString(user.description, 'html'),
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    let uid = localStorage.getItem('uid')
    let data = { user_id: uid }
    Axios.post('student/student_registered_course', data)
      .then((res) => {
        let orders = res && res?.data?.data
        console.log('::::::::::::::', orders)
        setOrdersdata(orders)
        console.log('orderssss', ordersdata)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const uploadImage = async (e) => {
    setSelectedFile(e.target.files[0])
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setUser({ ...user, image: reader.result })
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }
  const handleUpdate = (image) => {
    let data = user
    data.image = image
    console.log('data', data)
    var error = 'no'
    if (user.first_name == '' || user.first_name == null) {
      setErrorfname(true)
      error = 'yes'
    }
    if (user.last_name == '' || user.last_name == null) {
      console.log('lname')

      setErrorlname(true)
      error = 'yes'
    }
    if (user.dob == '' || user.dob == null) {
      console.log('dob')

      setErrordob(true)
      error = 'yes'
    }
    if (user.gender == 'Select Gender' || user.gender == null) {
      setErrorgender(true)
      error = 'yes'
    }

    if (user.preference_value == 'Select Language' || user.preference_value == null) {
      setErrorLang(true)
      error = 'yes'
    }
    if (user.address == '' || user.address == null) {
      setErroraddress(true)
      error = 'yes'
    }

    if (user.phone_number == '' || user.phone_number == null) {
      setErrormobi(true)
      error = 'yes'
    }

    if (user.city == '' || user.city == null) {
      setErrorcity(true)
      error = 'yes'
    }
    if (user.state == '' || user.state == null) {
      setErrorstate(true)
      error = 'yes'
    }
    if (user.pincode == '' || user.pincode == null) {
      setErrorpin(true)
      error = 'yes'
    }
    else if (user.pincode.toString().length < 5) {
      setErrorPincode(true)
      error = 'yes'
    }
    if (user.region == '' || user.region == null || user.region == "Select Region") {
      setErrorregion(true)
      error = 'yes'
    } else {
      setRegion(true)
    }
    const regex = /^([0|\+[0-9]{1,5})?([0-9]{9})$/
    if (!regex.test(user.phone_number)) {
      toast('Please Enter Valid Mobile Number', {
        type: 'info',
        theme: 'dark',
        position: 'bottom-right',
      })
      error = 'yes'
    }
    // else {
    //   error = 'no'
    // }

    if (error == 'no') {
      console.log(user.region, 'user.region')
      // return;

      setWaitstatus(true)
      Axios.post('user_details/update_profile', data)
        .then((res) => {
          setWaitstatus(false)
          if (res?.data?.status === 200) {
            let msg = res?.data?.message
            if (msg == 'failed') {
              let error = res?.data?.error

              if (error.includes('email_id')) {
                var alert_msg = 'Email already registered'
                var type = 'warning'
              }
              if (error.includes('phone_number')) {
                var alert_msg = 'Mobile number already registered'
                var type = 'warning'
              }
            } else {
              var alert_msg = 'Profile has been Updated..'
              var type = 'success'
              localStorage.setItem('isUpdated', true)
              localStorage.setItem('region', user.region)
              //if(user)
              remove_validation()
            }

            toast(alert_msg, {
              type: `${type}`,
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              // type: type,
            })
            // setIsChanged(!isChanged);
            //history.push("/profile");
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const remove_validation = () => {
    setErrorfname(false)
    setErrorlname(false)
    setErrordob(false)
    setErrorgender(false)
    setErroraddress(false)
    setErrormobi(false)
    setErrorcity(false)
    setErrorstate(false)
    setErrorpin(false)
    setErrorregion(false)
    setErrorLang(false)
  }

  const changePassword = () => {
    //     alert("change password");
    let user_id = localStorage.getItem('uid')
    let dataSending = { ...object }
    const re = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$',
    )
    const pass1 = re.test(object.new_password)
    if (
      object.old_password === '' ||
      object.new_password === '' ||
      values.confirmPassword === ''
    ) {
      alert('Please enter all fields')
    } else if (!pass1) {
      alert(
        'Password should be 8 characters with at least 1 (Capital Letter, Numeric, Special Character)',
      )
    } else if (object.new_password !== values.confirmPassword) {
      alert('Password does not match')
    } else {
      let copy = { ...object }
      let p = copy.old_password
      let r = copy.new_password
      dataSending.old_password = CryptoJS.AES.encrypt(p, 'rushi').toString()
      dataSending.new_password = CryptoJS.AES.encrypt(r, 'rushi').toString()
      dataSending.user_id = user_id
      setPwdwaitstatus(true)
      Axios.post('user_details/change_password', dataSending)
        .then((res) => {
          setPwdwaitstatus(false)
          if (res.data.message !== 'failed') {
            toast('Password changed successfully', {
              position: 'bottom-center',
              type: 'success',
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setObject({
              old_password: '',
              new_password: '',
            })
            setValues({
              ...values,
              showOldPassword: false,
              showPassword: false,
              showConfirmPassword: false,
            })
            navigate('/logout')
            //history.push("/profile");
          } else {
            toast(`${res?.data?.error}`, {
              position: 'bottom-center',
              type: 'info',
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
        .catch((err) => {
          toast('Something went wrong', {
            position: 'top-center',
            type: 'error',
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setObject({
            old_password: '',
            new_password: '',
          })
          setValues({
            ...values,
            showOldPassword: false,
            showPassword: false,
            showConfirmPassword: false,
          })
          //     console.log(err);
        })
    }
  }
  const handleUpdateProfile = () => {
    // aleDate of Birth *rt(isUpdated);
    if (!isUpdated || user.region == '' || user.region == null) {
      toast('Please Update Profile to continue', {
        type: 'warning',
        // position: "bottom-right",
        theme: 'dark',
      })
    }
  }

  console.log("hhhhh", user.preference_value, errorLang)
  return (
    <div>
      {/*My Account section start*/}
      {localStorage.getItem('uid') ? (
        <div className="my-account-section section section-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {/* My Account Tab Menu Start */}
                  <div className="col-lg-3 col-12">
                    <div className="myaccount-tab-menu nav" role="tablist">
                      <a
                        href="#dashboad"
                        data-bs-toggle="tab"
                        onClick={() => handleUpdateProfile()}
                        className={!isUpdated ? 'disabled' : null}
                      >
                        <i className="far fa-tachometer-alt-slow" />
                        Dashboard
                      </a>
                      <a
                        href="#orders"
                        data-bs-toggle="tab"
                        onClick={() => handleUpdateProfile()}
                        className={!isUpdated ? 'disabled' : null}
                      >
                        <i className="fa fa-cart-arrow-down" /> Orders
                      </a>

                      <a
                        href="#account-info"
                        data-bs-toggle="tab"
                        className="active"
                      >
                        <i className="fa fa-user" /> Account Details
                      </a>
                    </div>
                  </div>
                  {/* My Account Tab Menu End */}
                  {/* My Account Tab Content Start */}
                  <div className="col-lg-9 col-12">
                    <div className="tab-content" id="myaccountContent">
                      {/* Single Tab Content Start */}
                      <div
                        className="tab-pane fade"
                        id="dashboad"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3> {localStorage.getItem('uname')}</h3>
                          <div className="welcome mb-20">
                            {/* <p>
                            I'm, <strong>Memoriser</strong>
                          </p> */}
                          </div>
                          <p className="mb-0">
                            {user.description && parse(user.description)}
                          </p>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="orders"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3>Orders</h3>
                          <div className="myaccount-table table-responsive text-center">
                            <table className="table table-bordered">
                              <thead className="thead-light">
                                <tr>
                                  <th>No</th>
                                  <th>Name</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Total</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ordersdata &&
                                  ordersdata?.map((item, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.course_name}</td>
                                        <td>{item.date}</td>
                                        <td>Registered</td>
                                        <td>{item.amount}</td>
                                        <td>
                                          <a
                                            onClick={() =>
                                              imageClicked(item.course_id)
                                            }
                                            className="ht-btn black-btn o-details"
                                          >
                                            View
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* Single Tab Content End */}

                      {/* Single Tab Content Start */}
                      <div
                        className="tab-pane fade show active"
                        id="account-info"
                        role="tabpanel"
                      >
                        <div className="myaccount-content">
                          <h3>Account Details</h3>
                          <div className='high-txt'>
                            Please make sure to include the full postal address in the profile. This address will be used to mail the Gita semester books. Invalid and incomplete address will delay or miss the books shipment                          </div>
                          <div className="account-details-form">
                            <form action="#">
                              <div className="row align-cont">
                                <div className="col-lg-12 col-12 form-group mg-t-30 u-l-img">
                                  {/* <img src={user.image} alt="user" /> */}
                                  <img
                                    src={user?.image}
                                    alt="user"
                                    className="image-fluid pointer"
                                    onClick={() =>
                                      document.getElementById('upload').click()
                                    }
                                  />
                                  <i
                                    className="fas fa-cloud-upload-alt pointer"
                                    style={{
                                      position: 'relative',
                                      top: '0rem',
                                      left: '9rem',
                                      // color: "#fff",
                                    }}
                                    onClick={() =>
                                      document.getElementById('upload').click()
                                    }
                                  ></i>
                                  {/* <input
                                  type="file"
                                  accept=".jpg,.png"
                                  className="form-control-file"
                                  onChange={(e) => uploadImage(e)}
                                /> */}
                                  <center>
                                    <input
                                      id="upload"
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => uploadImage(e)}
                                      style={{ display: 'none' }}
                                    />
                                  </center>
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">
                                    First Name *
                                  </label>
                                  <input
                                    id="first_name"
                                    name="first_name"
                                    placeholder="First Name"
                                    type="text"
                                    value={user?.first_name}
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        first_name: e.target.value,
                                      })
                                    }
                                  />
                                  {(user?.first_name === "" || user?.first_name === null) && (
                                    <span className="pwd-err">
                                      {errorfname ? 'Firstname is required' : ''}
                                    </span>
                                  )}
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">
                                    Last Name *
                                  </label>
                                  <input
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Last Name"
                                    type="text"
                                    value={user?.last_name}
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        last_name: e.target.value,
                                      })
                                    }
                                  />
                                  {(user?.last_name === "" || user?.last_name === null) && (
                                    <span className="pwd-err">
                                      {errorlname ? 'Lastname is required' : ''}
                                    </span>
                                  )}
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">Gender *</label>
                                  <select
                                    class="form-control "
                                    name="gender"
                                    id="gender"
                                    value={user?.gender}
                                    onChange={handleChange}
                                  >
                                    <option>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                  </select>


                                  {console.log(user, '#############3')}

                                  {(user?.gender === "" || user?.gender === "Select Gender" || user?.gender === null) &&
                                    <span className="pwd-err">
                                      {errorgender ? 'Gender is required' : ''}
                                    </span>
                                  }


                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">
                                    Date of Birth *
                                  </label>
                                  <DatePicker
                                    label="Date of Birth"
                                    value={user.dob ? formatDate(user.dob) : ''}
                                    onChange={(e) =>
                                      setUser({ ...user, dob: formatDate(e) })
                                    }
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />

                                  {(user?.dob === "" || user?.dob === null) && (
                                    <span className="pwd-err">
                                      {errordob
                                        ? 'Date of Birth is required'
                                        : ''}
                                    </span>
                                  )}

                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">
                                    Mobile Number *
                                  </label>
                                  <input
                                    id="phone_number"
                                    name="phone_number"
                                    placeholder="Mobile Number"
                                    type="text"
                                    // step="0.01"
                                    value={user?.phone_number}
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        phone_number: e.target.value,
                                      })
                                    }
                                  />
                                  {(user?.phone_number === "" || user?.phone_number === null) &&
                                    (<span className="pwd-err">
                                      {errormobi
                                        ? 'Mobile Number is required'
                                        : ''}
                                    </span>
                                    )}
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">
                                    Address line1 *
                                  </label>
                                  <input
                                    id="address"
                                    name="address"
                                    placeholder="Address Line 1"
                                    type="text"
                                    value={user?.address}
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        address: e.target.value,
                                      })
                                    }
                                  />
                                  {(user?.address === "" || user?.address === null) && (
                                    <span className="pwd-err">
                                      {erroraddress
                                        ? 'Address Line1  is required'
                                        : ''}
                                    </span>
                                  )}
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">City *</label>
                                  <input
                                    id="city"
                                    name="city"
                                    placeholder="City Name"
                                    type="text"
                                    value={user?.city}
                                    onChange={(e) =>
                                      setUser({ ...user, city: e.target.value })
                                    }
                                  />
                                  {(user?.city === "" || user?.city === null) && (
                                    <span className="pwd-err">
                                      {errorcity ? 'City is required' : ''}
                                    </span>
                                  )}
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">State *</label>
                                  <input
                                    id="state"
                                    name="state"
                                    placeholder="State Name"
                                    type="text"
                                    value={user?.state}
                                    onChange={(e) =>
                                      setUser({
                                        ...user,
                                        state: e.target.value,
                                      })
                                    }
                                  />
                                  {(user?.state === "" || user?.state === null) && (
                                    <span className="pwd-err">
                                      {errorstate ? 'State is required' : ''}
                                    </span>
                                  )}
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">Pincode *</label>
                                  <input
                                    id="pincode"
                                    name="pincode"
                                    placeholder="Pincode"
                                    type="text"
                                    // pattern="[0-9]*"  // Only allows numeric characters
                                    value={
                                      user?.pincode === 0 ? '' : user?.pincode
                                    }
                                    onChange={(e) => {
                                      // const userInput = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                      const userInput = e.target.value

                                      // const isValidPincode = userInput.length > 0 && userInput.length >= 5;
                                      setUser({
                                        ...user,
                                        pincode: userInput,
                                      })
                                      // setErrorPincode(!isValidPincode); // Update error state based on pincode validity
                                    }}
                                  />
                                  {(user?.pincode === "" || user?.pincode === null) ? (
                                    <span className="pwd-err">
                                      {errorpin ? 'Pincode is required' : ''}
                                    </span>
                                  ) : <span className="pwd-err">
                                    {user?.pincode.toString().length >= 0 && user?.pincode.toString().length < 5 ? 'Pincode must be minimum 5' : ''}
                                  </span>



                                  }

                                  {/* <span className="pwd-err">
                                    {errorpincode ? 'Pincode must be minimum 5' : ''}
                                  </span> */}

                                </div>
                                <div className="col-lg-12 col-12 mb-30">
                                  <label htmlFor="usernameOne">Region *</label>
                                  <select
                                    class="form-control "
                                    name="region"
                                    id="region"
                                    value={user.region}
                                    onChange={handleChangereg}
                                    disabled={reg}
                                  >
                                    <option>Select Region</option>
                                    {currency.map((name) => (
                                      <option value={name.Country}>
                                        {name.Country +
                                          '(' +
                                          name.CountryCode +
                                          ')'}
                                      </option>
                                    ))}
                                  </select>
                                  {/* {user?.region === "" && ( */}
                                  {/* <span className="pwd-err">
                                      {!errorregion && user?.region === ""? 'Region is required' : <span><b>Note: If you want to update the region, please contact the admin.</b></span>}
                                    </span> */}
                                  {/* )} */}

                                  {/* 
                                  <span className="pwd-err">
                                    {!errorregion && user?.region === "" ? (
                                      'Region is required'
                                    ) : (
                                      <span>
                                        <b>Note: If you want to update the region, please contact to admin.</b>
                                      </span>
                                    )}
                                  </span> */}
                                  {/* <span className="pwd-err">
                                  {user?.region === "" && (
                                    {reg === false ? 'Region is required' : <b>Note: If you want to update the region, please contact the admin.</b>}
                                  </span> */}

                                  <span className={!errorregion ? "" : "pwd-err"}>
                                    {errorregion ? (
                                      'Region is required'
                                    ) : (
                                      <span>
                                        <b style={{ color: "#fe880e" }}>Note: If you want to update the region, please contact to admin.</b>
                                      </span>
                                    )}
                                  </span>
                                </div>

                                <div className="col-lg-6 col-12 mb-30">
                                  <label htmlFor="usernameOne">Language Preference*</label>
                                  <select
                                    class="form-control"
                                    name="preference_value"
                                    id="preference_value"
                                    value={user?.preference_value}
                                    onChange={handleChangePreferenece}
                                  >
                                    <option >Select Language</option>
                                    <option value="Telugu">Telugu</option>
                                    <option value="English">English</option>
                                    <option value="Sanskrit">Sanskrit</option>
                                  </select>

                                  {console.log(user, '#############3')}

                                  {(user?.preference_value === "" || user?.preference_value === "Select Language" || user?.preference_value === null || user?.preference_value === undefined) &&
                                    <span className="pwd-err">
                                      {errorLang ? 'Language Preference is required' : ''}
                                    </span>
                                  }

                                </div>






                                <div className="col-lg-12 col-12 mb-30">
                                  <label htmlFor="usernameOne">
                                    {' '}
                                    About Myself
                                  </label>
                                  <RichTextEditor
                                    id="description"
                                    name="description"
                                    placeholder="description"
                                    type="text"
                                    value={dummydes}
                                    onChange={(e) => {
                                      setUser({
                                        ...user,
                                        description: e.toString('html'),
                                      })
                                      setDummydes(e)
                                    }}
                                  />

                                  {/* <span className="pwd-err">
                                  {errorstate ? "Pincode is required" : ""}
                                </span> */}
                                </div>

                                <div className="col-12">
                                  <button
                                    className="btn btn-primary btn-hover-secondary"
                                    type="button"
                                    onClick={() =>
                                      handleUploadImage(selectedFile)
                                    }
                                  // className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                                  >
                                    {waitstatus ? 'Please Wait..' : 'Update'}
                                  </button>
                                </div>
                              </div>
                              <div className="row align-cont mg-t-40">
                                <h5>Change Password</h5>
                                <div className="col-12 mb-30">
                                  <input
                                    id="password"
                                    name="password"
                                    placeholder="Current Password *"
                                    type={showcpwd ? 'text' : 'password'}
                                    values={values.old_password}
                                    onChange={(e) =>
                                      setObject({
                                        ...object,
                                        old_password: e.target.value,
                                      })
                                    }
                                  />
                                  <i
                                    className={`far fa-eye${!showcpwd ? '' : '-slash'
                                      }`}
                                    id="togglePassword"
                                    style={{
                                      marginLeft: '-30px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => showcpassword()}
                                  />
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <input
                                    id="new_password"
                                    name="new_password"
                                    placeholder="New Password *"
                                    type={showpwd ? 'text' : 'password'}
                                    values={values.new_password}
                                    onChange={(e) =>
                                      setObject({
                                        ...object,
                                        new_password: e.target.value,
                                      })
                                    }
                                  />
                                  <i
                                    className={`far fa-eye${!showpwd ? '' : '-slash'
                                      }`}
                                    id="togglePassword"
                                    style={{
                                      marginLeft: '-30px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => showpassword()}
                                  />
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                  <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    type={showcnfpwd ? 'text' : 'password'}
                                    value={values.confirmPassword}
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        confirmPassword: e.target.value,
                                      })
                                    }
                                  />
                                  <i
                                    className={`far fa-eye${!showcnfpwd ? '' : '-slash'
                                      }`}
                                    id="togglePassword"
                                    style={{
                                      marginLeft: '-30px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => showcnfpassword()}
                                  />
                                </div>
                                <div className="col-12">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-hover-secondary"
                                    onClick={() => changePassword()}
                                  >
                                    {pwdwaitstatus
                                      ? 'Please Wait..'
                                      : 'Change Password'}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* Single Tab Content End */}
                    </div>
                  </div>
                  {/* My Account Tab Content End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
      {/*My Account section end*/}
    </div>
  )
}

export default MyAccount
